export default defineNuxtRouteMiddleware(async (to) => {
  const { loggedIn } = useUserSession()

  if (to?.meta?.auth === false)
    return

  if (!loggedIn.value) {
    // eslint-disable-next-line no-console
    console.log('not logged in')

    if (to.fullPath !== '/')
      return navigateTo(`/login?returnUrl=${to.fullPath}`)

    return navigateTo('/login')
  }
})